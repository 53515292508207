import * as React from "react";
import {Form, Message, Segment, SegmentGroup} from "semantic-ui-react";
import {
    AgencySCUUnit,
    ApiResult,
    RequestUnit,
    SCUZone,
} from "@bryxinc/lunch/models";

import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    WithApi,
    withContext,
    WithLocal,
    WithTranslation,
} from "@bryxinc/lunch/context";

export type EditSCUZoneViewStatus =
    | { key: "hidden" }
    | { key: "shown"; scuId: string; agencyId: string };

interface ZoneSettingsProps
    extends WithLocal,
        WithApi<BryxApi>,
        WithTranslation {
    zones: SCUZone[];
    scuId: string;
    agencyId: string;
    onScuUnits: AgencySCUUnit[];
    viewStatus: EditSCUZoneViewStatus;
}

interface ZoneSettingsState {
    zones: SCUZone[];
    actionState:
        | { key: "ready" }
        | { key: "sending" }
        | { key: "loading" }
        | { key: "error"; message: string }
        | { key: "success" };
    unitOptions: RequestUnit[];
    onScuUnits: string[];
}

export class ZoneSettings extends React.Component<ZoneSettingsProps,
    ZoneSettingsState> {
    constructor(props: ZoneSettingsProps, context: any) {
        super(props, context);
        this.state = {
            zones: props.zones,
            actionState: {key: "loading"},
            unitOptions: [],
            onScuUnits: props.onScuUnits.map((u) => u.id),
        };
    }

    private static getDefaultState(): ZoneSettingsState {
        return {
            actionState: {key: "loading"},
            unitOptions: [],
            zones: [],
            onScuUnits: [],
        };
    }

    private loadAllUnits() {
        this.props.api.getAllUnits(this.props.agencyId, (result) => {
            if (result.success) {
                this.setState({
                    unitOptions: result.value,
                    actionState: {key: "ready"},
                });
            } else {
                this.setState({
                    actionState: {key: "error", message: result.message},
                });
            }
        });
    }

    componentDidMount() {
        this.loadAllUnits();
    }

    handleDismiss() {
        if (this.state.actionState.key == "success") {
            this.setState({actionState: {key: "ready"}});
        }
    }

    editZoneUnits(zoneId: string, units: string[], target: HTMLButtonElement) {
        target.classList.toggle("loading");
        this.setState({
            actionState: {key: "sending"},
        });
        this.props.api.editSCUZone(
            this.props.agencyId,
            this.props.scuId,
            zoneId,
            null,
            units,
            null,
            null,
            null,
            (result: ApiResult<SCUZone>) => {
                if (result.success) {
                    this.props.api.pushScuConfig(
                        this.props.agencyId,
                        this.props.scuId,
                        (resultPush: ApiResult<string>) => {
                            if (resultPush.success) {
                                const {zones} = this.state;
                                zones.forEach((z, i) => {
                                    if (z.id == zoneId) {
                                        zones[i] = result.value;
                                    }
                                });

                                this.setState({
                                    zones: zones,
                                    actionState: {key: "success"},
                                });
                                target.classList.toggle("loading");
                            } else {
                                this.setState({
                                    actionState: {key: "error", message: resultPush.message},
                                });
                                target.classList.toggle("loading");
                            }
                        },
                    );
                } else {
                    this.setState({
                        actionState: {key: "error", message: result.message},
                    });
                    target.classList.toggle("loading");
                }
            },
        );
    }

    juggleOnScuStatus(unitIds: string[]) {
        // This adds units to the SCU if needed if they get zoned on the frontend
        const {onScuUnits} = this.state;
        unitIds.forEach((unitId) => {
            if (onScuUnits.indexOf(unitId) < 0) {
                this.setState({actionState: {key: "sending"}});
                this.props.api.addUnitToScu(
                    this.props.agencyId,
                    this.props.scuId,
                    unitId,
                    (result: ApiResult<null>) => {
                        if (result.success) {
                            this.setState({
                                actionState: {key: "ready"},
                                onScuUnits: onScuUnits.concat(...[unitId]),
                            });
                        } else {
                            this.setState({
                                actionState: {key: "error", message: result.message},
                            });
                        }
                    },
                );
            }
        });
    }

    componentDidUpdate(nextProps: ZoneSettingsProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key == "shown"
        ) {
            this.setState(ZoneSettings.getDefaultState(), () => this.loadAllUnits());
        }
    }

    render() {
        const {actionState, unitOptions, onScuUnits, zones} = this.state;
        // sort the dropdowns so units on the scu get listed first cuz sure
        const sorter = (u1: RequestUnit, u2: RequestUnit): number => {
            if (onScuUnits.indexOf(u1.id) > onScuUnits.indexOf(u2.id)) {
                return -1;
            } else if (onScuUnits.indexOf(u1.id) < onScuUnits.indexOf(u2.id)) {
                return 1;
            } else {
                return 0;
            }
        };

        return (
            <Segment.Group style={{height: "100%"}}>
                <Segment>
                    {actionState.key == "error" ? (
                        <Message negative content={actionState.message}/>
                    ) : null}
                    {actionState.key == "success" ? (
                        <Message
                            onDismiss={this.handleDismiss.bind(this)}
                            positive
                            content={this.props.t(
                                "stationAlerting.configurationModal.saveConfirm",
                            )}
                        />
                    ) : null}
                    {zones.map((zone) => (
                        <SegmentGroup key={zone.id}>
                            <Segment>
                <span style={{fontWeight: "bold", fontSize: "16px"}}>
                  {zone.name}
                </span>
                                <br/>
                                <br/>
                                <Form>
                                    <Form.Field>
                                        <label>
                                            {this.props.t(
                                                "stationAlerting.configurationModal.zones.unitInputLabel",
                                            )}
                                        </label>
                                        <Form.Dropdown
                                            multiple
                                            selection
                                            search
                                            options={unitOptions
                                                .sort(sorter)
                                                .map((u) => ({key: u.id, value: u.id, text: u.name}))}
                                            onChange={(e, d) => {
                                                zone.units = d.value as string[];
                                                zones.forEach((z, i) => {
                                                    if (z.id == zone.id) {
                                                        zones[i] = zone;
                                                    }
                                                });
                                                this.setState({zones: zones});
                                                this.juggleOnScuStatus(d.value as string[]);
                                            }}
                                            value={zone.units}
                                        />
                                    </Form.Field>
                                    <Form.Button
                                        content={this.props.t(
                                            "stationAlerting.configurationModal.saveButtonText",
                                        )}
                                        color="blue"
                                        icon="save"
                                        loading={false}
                                        onClick={(e, d) => {
                                            this.editZoneUnits(zone.id, zone.units, e.currentTarget);
                                        }}
                                    />
                                </Form>
                            </Segment>
                        </SegmentGroup>
                    ))}
                </Segment>
            </Segment.Group>
        );
    }
}

export default withContext(ZoneSettings, "api", "local");
